export enum CompetitionCMSSteps {
    General = 'general',
    Tournament = 'tournament',
    Registration = 'registration',
    Participants = 'participants',
    Chapters = 'chapters',
    Details = 'details',
    Groups = 'groups',
    Admin = 'admin',
}

export const CompetitionCMSRoundRobinStepsArray = [
    CompetitionCMSSteps.General,
    CompetitionCMSSteps.Tournament,
    CompetitionCMSSteps.Registration,
    CompetitionCMSSteps.Details,
    CompetitionCMSSteps.Participants,
    CompetitionCMSSteps.Groups,
];

export const CompetitionCMSStepsArray = [
    CompetitionCMSSteps.General,
    CompetitionCMSSteps.Tournament,
    CompetitionCMSSteps.Registration,
    CompetitionCMSSteps.Details,
    CompetitionCMSSteps.Participants,
    CompetitionCMSSteps.Groups,
];

export const CompetitionCMSStepsArrayAdmin = [
    CompetitionCMSSteps.General,
    CompetitionCMSSteps.Tournament,
    CompetitionCMSSteps.Registration,
    CompetitionCMSSteps.Details,
    CompetitionCMSSteps.Participants,
    CompetitionCMSSteps.Groups,
    CompetitionCMSSteps.Admin,
];

export const CompetitionCMSCourseStepsArray = [
    CompetitionCMSSteps.General,
    CompetitionCMSSteps.Chapters,
    CompetitionCMSSteps.Registration,
    CompetitionCMSSteps.Participants,
    CompetitionCMSSteps.Details,
];
