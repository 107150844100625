import {ChallengeType, Competition, CompetitionStatus, CompetitionUiData, CompetitionUiPreviewData} from '@shared/models/competition';
import {CompetitionCMSCourseStepsArray, CompetitionCMSSteps, CompetitionCMSStepsArray} from '@shared/models/cms';
import {endOfDay, isAfter, isSameDay, isWithinInterval, startOfDay} from 'date-fns';
import {getDatabaseDate} from '@shared/models/firestore-timestamp';

const competitionUiData = (competition: Competition): CompetitionUiData => {
    const {id, name, visuals = {}, status, challengeType = ChallengeType.Competition, currentGroupId, community, organizerIds} = competition;
    return {id, name, visuals, challengeType, status, ...(organizerIds && {organizerIds}), ...(currentGroupId && {currentGroupId}), ...(community && {community})};
};

const competitionUiPreviewData = (competition: Competition): CompetitionUiPreviewData => {
    const {id, name, visuals = {}, startsAt, endsAt, status, registration, challengeType = ChallengeType.Competition, divots, currentGroupId, organizerIds} = competition;
    return {id, name, visuals, startsAt, endsAt, status, registration, isPreview: true, challengeType, ...(organizerIds && {organizerIds}), ...(divots && {divots}), ...(currentGroupId && {currentGroupId})};
};

const getCurrentStage = (competition: Competition) => {
    return competition.stages[competition.currentStageId];
};

const isGeneralInfoStepCompleted = (competition: Competition) => {
    return !!competition.name && !!competition.registration.locations.length && competition.cms?.viewedSteps?.includes(CompetitionCMSSteps.General);
};
const isTournamentStepCompleted = (competition: Competition) => {
    return competition.challengeType === ChallengeType.Course ||
    Object.values(competition.stages).length > 0 && competition.cms?.viewedSteps?.includes(CompetitionCMSSteps.Tournament);
};

const isRegistrationStepCompleted = (competition: Competition) => {
    return !!competition.registration.entryPrice && competition.cms?.viewedSteps?.includes(CompetitionCMSSteps.Registration);
};

const isRegistrationsInfoStepCompleted = (competition: Competition) => {
    return !!competition.name && !!competition.registration.locations.length && competition.cms?.viewedSteps?.includes(CompetitionCMSSteps.Participants);
};

const isDetailsStepCompleted = (competition: Competition) => {
    return competition.cms?.viewedSteps?.includes(CompetitionCMSSteps.Details);
};

const isChaptersCompleted = (competition: Competition) => {
    return competition.challengeType !== ChallengeType.Course || competition.cms?.viewedSteps?.includes(CompetitionCMSSteps.Chapters);
};

const isGroupsCompleted = (competition: Competition) => {
    return competition.challengeType !== ChallengeType.Course || competition.cms?.viewedSteps?.includes(CompetitionCMSSteps.Groups);
};

const getCompletedSteps = (competition: Competition) => {
    if(!competition) {
        return [];
    }

    const completedSteps = [];

    if(isGeneralInfoStepCompleted(competition)) {
        completedSteps.push(CompetitionCMSSteps.General);
    }

    if(isTournamentStepCompleted(competition)) {
        completedSteps.push(CompetitionCMSSteps.Tournament);
    }

    if(isRegistrationStepCompleted(competition)) {
        completedSteps.push(CompetitionCMSSteps.Registration);
    }

    if(isRegistrationsInfoStepCompleted(competition)) {
        completedSteps.push(CompetitionCMSSteps.Participants);
    }

    if(isDetailsStepCompleted(competition)) {
        completedSteps.push(CompetitionCMSSteps.Details);
    }

    if(isChaptersCompleted(competition)) {
        completedSteps.push(CompetitionCMSSteps.Chapters);
    }

    if(isGroupsCompleted(competition)) {
        completedSteps.push(CompetitionCMSSteps.Groups);
    }

    return completedSteps;
};

const isCompetitionPublishable = (competition?: Competition) => {
    if(!competition) {
        return false;
    }
    const startsAt = getDatabaseDate(competition.startsAt);

    const stepsCompleted = isChallengeCompetition(competition) ?
        getCompletedSteps(competition).length >= CompetitionCMSStepsArray.length :
        getCompletedSteps(competition).length >= CompetitionCMSCourseStepsArray.length;

    const isUnpublished = competition.status === CompetitionStatus.Unpublished;
    const isStartsTodayOrPast = isSameDay(startsAt, new Date()) || isAfter(startsAt, new Date());

    return stepsCompleted && isUnpublished && isStartsTodayOrPast;
};


const isCompetitionOpenForRegister = (competition: Pick<Competition, 'registration' | 'status'>) => {
    const now = new Date();

    const {startsAt, endsAt} = competition.registration;

    return isWithinInterval(now, {start: startOfDay(getDatabaseDate(startsAt)), end: endOfDay(getDatabaseDate(endsAt))}) &&
    competition.status !== CompetitionStatus.Finished;
};

const isCompetitionFree = (competition: Competition) => {
    return competition.registration.entryPrice.amount === 0;
};

const isChallengeCompetition = (competition: Pick<Competition, 'challengeType'>) => {
    const {challengeType = ChallengeType.Competition} = competition;
    return challengeType === ChallengeType.Competition;
};

const isChallengeCourse = (competition: Pick<Competition, 'challengeType'>) => {
    const {challengeType = ChallengeType.Competition} = competition;
    return challengeType === ChallengeType.Course;
};

export {competitionUiData, getCurrentStage, getCompletedSteps, isCompetitionPublishable, competitionUiPreviewData, isCompetitionOpenForRegister, isCompetitionFree, isChallengeCompetition, isChallengeCourse};
