import {CompetitionGroupFixture, Fixture, FixtureStatus, ResultStatus, Score} from '@shared/models/fixture';
import {FixtureRuleType, LiveScoreFixtureRulesIds} from '@shared/models/fixture-rule';
import {Participant, ParticipantType, participantIsTeam, participantIsUser} from '@shared/models/participant';
import {first, identity, last, pick, range, sortBy, toSafeInteger} from 'lodash';
import {isMatchPlay, isTexasScramble} from './golf/fixture-rule-fn';
import {matchPlay1VS1ScoresText, matchPlayScoreText} from './golf/matchPlay-fn';
import {Club} from '@shared/models/club';
import {TeamUiData} from '@shared/models/team';
import {UNLIMITED_PLAYERS_VALUE} from './fixtureCreateConstants';
import {findWinners} from './score';
import {findWinningScore} from './result-fn';
import {isChallengeCourse} from './competition-fn';
import {isNil} from './lang';

const fixtureScoreText = (fixture: Fixture, t: (key: string) => string) => {
    const {participants, participantsOrder, result, rule} = fixture;

    if(!result) {
        return '';
    }

    const [leftParticipant, rightParticipant] = [
        participants[participantsOrder[0]],
        participants[participantsOrder[1]],
    ];

    const leftScore = result?.scores?.find((score) => score.participantId === leftParticipant?.id)?.score ?? 0;
    const rightScore = result?.scores?.find((score) => score.participantId === rightParticipant?.id)?.score ?? 0;

    const {isDraw} = result;

    if(isDraw) {
        return t('fixture.result.scoreboard.draw');
    }

    if(isMatchPlay(rule)) {
        if(fixture.scorecard === undefined) {
            // No Live Score
            return matchPlayScoreText(Math.max(leftScore, rightScore), rule.dormie ?? true);
        }
        const holesTotal = fixture.holes ?? 18;
        const holesPlayed = leftScore + rightScore;
        const holesLeft = holesTotal - holesPlayed;
        const dormie = holesLeft > 0;
        return matchPlay1VS1ScoresText(leftScore, rightScore, holesLeft, dormie);
    }

    if(isTexasScramble(rule)) {
        return findWinningScore(result)?.score.toString() ?? '';
    }

    return `${leftScore} - ${rightScore}`;
};

const isCompetitionFixture = (fixture: Fixture): boolean => {
    return fixture.competition !== undefined || fixture.competitionGroup !== undefined;
};

const toCompetitionGroupFixture = (fixture: Fixture): CompetitionGroupFixture =>
    pick(fixture, ['id', 'participantIds', 'participants', 'result', 'rule', 'round', 'knockoutMatchNumber', 'knockoutFixtureType', 'participantsOrder', 'scorecard', 'microBets']);

const winLoseScoreText = (score: number, t: (key: string) => string) => {
    if(score === 0) {
        return '';
    } else {
        return t('fixture.score.win');
    }
};

const isFixtureVerifiable = (fixture: Fixture, userAttemptingVerificationId: string): boolean => {
    return fixture.result?.status == ResultStatus.Pending &&
        fixture.participantIds.includes(userAttemptingVerificationId);
};

const isLastScoreUploader = (fixture: Fixture, uid: string): boolean => {
    const uploader = last(fixture.uploadScores)?.uploadedBy;
    if(!uploader) {
        return false;
    }

    if(fixture.participantType === ParticipantType.User) {
        return uploader === uid;
    } else {
        // Check if multiple teams
        if(Object.values(fixture.participantsOrder).length > 1){
            return findUserTeam(Object.values(fixture.participants), uid)?.participants[uploader] !== undefined;
        } else {
            return uploader === uid;
        }
    }
};

const findUserTeam = (participants: Participant[], uid: string): TeamUiData | undefined => {
    return participants.find((participant) => participantIsTeam(participant) && participant.participants[uid]) as TeamUiData;
};

const getAllFixtureTeams = (fixture: Pick<Fixture, 'participants'>) => {
    return Object.values(fixture.participants).filter(participantIsTeam);
};

const getAllFixtureUsers = (fixture: Pick<Fixture, 'participants'>) => {
    return Object.values(fixture.participants).filter((participant) => participantIsUser(participant));
};

const getAllParticipantsByType = (fixture: Pick<Fixture, 'participants'>, type: ParticipantType): Participant[] => {
    return type === ParticipantType.User ? getAllFixtureUsers(fixture) : getAllFixtureTeams(fixture);
};

const fixtureTeamParticipants = (allParticipants: Participant[], team: TeamUiData) => {
    return allParticipants.filter((participant) => participantIsUser(participant) && team.participants[participant.id]);
};

const getLeftParticipants = (fixture: Fixture) => {
    const {participants, participantType, participantsOrder} = fixture;
    const leftParticipant = participants[participantsOrder[0]];
    if(!leftParticipant) {
        return [];
    }

    if(participantType === ParticipantType.User) {
        return [leftParticipant].filter(identity);
    }

    const participantsArray = Object.values(participants);

    return leftParticipant ? sortBy(participantsArray.filter((p) => (leftParticipant as TeamUiData).participants[p.id]), 'id') : [];
};

const getRightParticipants = (fixture: Fixture) => {
    const {participants, participantType, participantsOrder} = fixture;
    const rightParticipant = participants[participantsOrder[1]];
    if(!rightParticipant) {
        return [];
    }
    if(participantType === ParticipantType.User) {
        return [rightParticipant].filter(identity);
    }

    const participantsArray = Object.values(participants);

    return rightParticipant ? sortBy(participantsArray.filter((p) => (rightParticipant as TeamUiData).participants[p.id]), 'id') : [];
};

const getParticipantsByOrder = (fixture: Fixture, order: number) => {
    const {participants, participantType, participantsOrder} = fixture;
    const participant = participants[participantsOrder[order]];
    if(!participant) {
        return [];
    }

    if(participantType === ParticipantType.User) {
        return [participant].filter(identity);
    }

    const participantsArray = Object.values(participants);

    return participant ? sortBy(participantsArray.filter((p) => (participant as TeamUiData).participants[p.id]), 'id') : [];
};

const getParticipantsOrdered = (fixture: Fixture) => {
    return Object.keys(fixture.participantsOrder).map(toSafeInteger).map((index) => fixture.participants[fixture.participantsOrder[index]]);
};

const getAllParticipantsWithSubParticipants = (fixture: Fixture): [Participant, Participant[]][] => {
    const participants = Math.max(...Object.keys(fixture.participantsOrder).map(toSafeInteger));
    return range(0, participants + 1).map((index) => [fixture.participants[fixture.participantsOrder[index]], getParticipantsByOrder(fixture, index)]);
};

const numberOfParticipants = (fixture: Fixture) => {
    const {rule} = fixture;
    const {numberOfParticipants = 1} = rule;

    const participants = getAllParticipantsByType(fixture, fixture.participantType);
    return Math.max(numberOfParticipants, participants.length);
};

const canUserDeleteFixture = (fixture: Fixture, uid?: string) => {
    const {competition, status} = fixture;
    if(!uid) {
        return false;
    }

    if(competition && isChallengeCourse(competition)) {
        return fixture.uids.includes(uid);
    }

    return fixture.uid === uid && status !== FixtureStatus.Played;
};

const canUserLeaveFixture = (fixture: Fixture, uid?: string) => {
    const {status} = fixture;
    if(!uid || isCompetitionFixture(fixture)) {
        return false;
    }

    return status !== FixtureStatus.Played && fixture.uid !== uid && fixture.participantIds.includes(uid);
};

const maxNumberOfPlayers = (fixture: Fixture) => {
    const {rule} = fixture;
    const {numberOfParticipants = 1, playersPerTeam = 1} = rule;
    return numberOfParticipants * playersPerTeam;
};

const currentInvitationSize = (fixture: Fixture) => {
    return fixture.invitationIds?.length ?? 0;
};

const getAllPlayers = (fixture: Fixture) => {
    return getAllParticipantsByType(fixture, ParticipantType.User);
};

const isUnlimitedPlayers = (fixture: Fixture) => {
    return maxNumberOfPlayers(fixture) === UNLIMITED_PLAYERS_VALUE;
};

const hasAllPlayers = (fixture: Fixture) => {
    return maxNumberOfPlayers(fixture) === getAllPlayers(fixture).length;
};

const isFinished = (fixture: Fixture) => {
    return fixture.status === FixtureStatus.Played;
};

const openInvitationSpots = (fixture: Fixture) => {
    return maxNumberOfPlayers(fixture) - currentInvitationSize(fixture) - getAllPlayers(fixture).length;
};

const getInvitationsArray = (fixture: Fixture): Participant[] => {
    const {invitationIds, invitations} = fixture;
    return invitationIds ? invitationIds.map(i => invitations?.[i]).filter(p => !isNil(p)) as Participant[] : [];
};

const hasOpenInvitationSpots = (fixture: Fixture) => {
    return openInvitationSpots(fixture) > 0;
};

const hasInvitations = (fixture: Fixture) => {
    return currentInvitationSize(fixture) > 0;
};

const getOpponent = (fixture: Fixture, uid: string) => {
    const participants = getAllParticipantsByType(fixture, fixture.participantType);
    return participants.find((participant) => participant.id !== uid);
};

const isFixturePlayable = (fixture: Fixture) => {
    return fixture.status === FixtureStatus.Playable || fixture.status === FixtureStatus.Upcoming;
};

const scoresToUpdateFixture = (uid: string, fixture: Fixture, scores: Score[]): Partial<Fixture> => {
    const winners = findWinners(fixture, scores);
    const winner = first(winners ?? []);
    const isDraw = winner === undefined;

    const {uploadScores = []} = fixture;

    const matchResult = {
        scores,
        isDraw,
        ...(winner && {winner: winner.id}),
        ...(winners && winners.length > 0 && {winners: winners.map(w => w.id)}),
        status: ResultStatus.Pending,
    };

    const newData: Partial<Fixture> = {
        ...(uploadScores.length == 0 && {firstScoreUploadedAt: new Date()}),
        result: matchResult,
        uploadScores: [...uploadScores, {
            ...matchResult,
            uploadedBy: uid,
            uploadedAt: new Date(),
        }],
        playedAt: new Date(),
        hasResult: true,
        status: FixtureStatus.Played,
    };

    return newData;
};

const matchPlayHasLiveScore = (fixture: Fixture) => {
    const {rule} = fixture;
    if(rule.id !== FixtureRuleType.MatchPlay) {
        return true;
    }

    return rule.numberOfParticipants === 2;
};

const participantTypeHasLiveScore = (fixture: Fixture) => {
    const {participantType, rule} = fixture;
    if(participantType === ParticipantType.Team && isTexasScramble(rule)) {
        return true;
    }

    return participantType === ParticipantType.User;
};

const fixtureHasLiveScore = (fixture:Fixture) => {
    return LiveScoreFixtureRulesIds.includes(fixture.rule.id as FixtureRuleType)
        && matchPlayHasLiveScore(fixture)
        && (fixture.location as Club)?.type === 'golf_course_database'
        && participantTypeHasLiveScore(fixture);
};

export {
    getLeftParticipants,
    getRightParticipants,
    getParticipantsByOrder,
    getAllParticipantsWithSubParticipants,
    hasAllPlayers,
    isCompetitionFixture,
    isLastScoreUploader,
    winLoseScoreText,
    numberOfParticipants,
    isFixtureVerifiable,
    findUserTeam,
    toCompetitionGroupFixture,
    getAllFixtureTeams,
    getAllFixtureUsers,
    getAllParticipantsByType,
    getOpponent,
    fixtureTeamParticipants,
    canUserDeleteFixture,
    isFinished,
    hasInvitations,
    currentInvitationSize,
    maxNumberOfPlayers,
    hasOpenInvitationSpots,
    openInvitationSpots,
    getInvitationsArray,
    canUserLeaveFixture,
    getParticipantsOrdered,
    isUnlimitedPlayers,
    isFixturePlayable,
    scoresToUpdateFixture,
    fixtureScoreText,
    fixtureHasLiveScore,
};
